import * as React from "react";
import { Parallax } from "react-parallax";
import { Card, Container, Row } from "react-bootstrap";

import image from "../../images/meeting.jpg";
import stretchinSvg from "../../images/stretching.svg";
import chooseSvg from "../../images/choose.svg";
import Layout from "../../components/layout";
import Section from "../../components/section";
import Typography from "../../components/typography";

const LoanOfficers = () => {
	return (
		<Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
			<Section size="md" color="light" staticHeight>
				<Parallax
					className="d-flex justify-content-center align-items-center h-100"
					bgImage={image}
					strength={750}>
					<Container className="text-center h-100">
						<div>
							<Typography type="head">Bank Loan</Typography>
						</div>
					</Container>
				</Parallax>
			</Section>
			<Section size="sm" color="dark" bg="light">
				<Container className=" py-5">
				<div className="hide-on-mobile">
					<Typography className="mb-3" type="title">
						What is a Bank Loan?
					</Typography>
					</div>
					<div className="hide-on-desktop text-center">
					<Typography className="mb-3" type="title">
						What is a Bank Loan?
					</Typography>
					</div>
					<Typography type="paragraph">
						A bank loan offers funds directly from a bank, the largest lending
						institution and most obvious place to get a small business loan.
						These loans usually offer large amounts of capital that can be used
						for anything in your business, and will be repaid over a long period
						of time in monthly installments. Bank loans can sometimes be more
						difficult to qualify for in comparison to loans from alternative
						lenders as they require higher credit scores and a longer time in
						business. However, if your business is able to qualify, bank loans
						can serve as a great source of low cost capital to stabilize cash
						flows and bolster growth.
					</Typography>
				</Container>
			</Section>
			<Section color="light" bg="dark">
				<Container className="text-center py-5">
					<Typography type="title">Loan Details</Typography>
					<Row className="d-flex justify-content-center mx-auto">
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Max. Loan Amount</Typography>
							<Typography type="subject">$20K to $15M</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Loan Term</Typography>
							<Typography type="subject">3 - 30 years</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Interest Rates</Typography>
							<Typography type="subject">3% - 12%</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Speed of Funding</Typography>
							<Typography type="subject">2 - 4 weeks</Typography>
						</Card>
					</Row>
					<Typography className="mt-5" type="title">
						Loan Requirements
					</Typography>
					<Row className="d-flex justify-content-center mx-auto">
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Annual Revenue</Typography>
							<Typography type="subject">$50,000+</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Credit Score</Typography>
							<Typography type="subject">670+</Typography>
						</Card>
						<Card
							style={{ maxWidth: "200px", minHeight: "125px" }}
							className="d-flex justify-content-center align-items-center mx-3">
							<Typography type="paragraph">Time in Business</Typography>
							<Typography type="subject">2+ years</Typography>
						</Card>
					</Row>
				</Container>
			</Section>
			<Section size="md">
				<Container
					fluid
					className="d-flex flex-nowrap h-100 align-items-center h-100 p-5">
					<div className="nom d-inline-block me-5">
						<img
							className="h-100"
							style={{ maxHeight: "275px" }}
							src={stretchinSvg}
							alt="Two People Agreeing on Document Terms"></img>
					</div>
					<div className="d-inline-block flex-full">
						<Row className="w-100 h-100">
							<div className="flex-full">
								<Typography type="title">Pros</Typography>
								<ul>
									<li>Low interest rates</li>
									<li>Funds can be used flexibly</li>
									<li>Builds up business credit</li>
									<li>Relationship with bank</li>
								</ul>
							</div>
							<div className="flex-full mr-5">
								<Typography type="title">Cons</Typography>
								<ul>
									<li>Hard to qualify</li>
									<li>Lots of paperwork</li>
									<li>Slower speed of funding</li>
									<li>Additional collateral required sometimes</li>
								</ul>
							</div>
						</Row>
					</div>
				</Container>
			</Section>
			<Section size="lg" bg="dark" color="light">
				<Container className="py-5">
					<div className="hide-on-mobile">
					<Typography type="title">How To Apply for a Bank Loan</Typography>
					<Typography type="paragraph">
						With our simple online application, the process to receive a Bank
						Loan is quick and easy.
					</Typography>
					</div>
					<div className="hide-on-desktop text-center">
					<Typography type="title">How To Apply for a Bank Loan</Typography>
					<Typography type="paragraph">
						With our simple online application, the process to receive a Bank
						Loan is quick and easy.
					</Typography>
					</div>
					<Row style={{ marginTop: "10%" }}>
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">1</span>
							</Typography>
							<div>
								<Typography type="subject">Prequalify Online</Typography>
								<Typography type="paragraph">
									Follow steps and enter basic information.
								</Typography>
							</div>
						</div>
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">2</span>
							</Typography>
							<div>
								<Typography type="subject">Apply</Typography>
								<Typography type="paragraph">
									Fill out an application and submit required documentation.
								</Typography>
							</div>
						</div>
						<div className="d-flex align-items-center flex-full tl-min py-4">
							<Typography type="subject">
								<span className="numList me-3">3</span>
							</Typography>
							<div>
								<Typography type="subject">Recieve Secure Funding</Typography>
								<Typography type="paragraph">
									After approval, funds will be directly deposited into your
									bank account and available for immediate use.
								</Typography>
							</div>
						</div>
					</Row>

					<div className="hide-on-mobile" style={{ marginTop: "10%" }}>
						<Typography type="title">Needed Documentation</Typography>
						<ul className="d-flex flex-wrap">
							<li className="m-0 me-5 mb-3">Business information</li>
							<li className="m-0 me-5 mb-3">Business plan</li>
							<li className="m-0 me-5 mb-3">Recent bank statements</li>
							<li className="m-0 me-5 mb-3">Other financial documents</li>
							<li className="m-0 me-5 mb-3">Tax returns</li>
						</ul>
					</div>
					<div className="hide-on-desktop" style={{ marginTop: "10%" }}>
						<Typography className="text-center" type="title">Needed Documentation</Typography>
						<ul className="d-flex flex-wrap">
							<li className="m-0 me-5 mb-3">Business information</li>
							<li className="m-0 me-5 mb-3">Business plan</li>
							<li className="m-0 me-5 mb-3">Recent bank statements</li>
							<li className="m-0 me-5 mb-3">Other financial documents</li>
							<li className="m-0 me-5 mb-3">Tax returns</li>
						</ul>
					</div>
				</Container>
			</Section>
			<Section size="md">
				<Container className="d-flex flex-nowrap h-100 align-items-center h-100 py-5">
					<div className="nom d-inline-block flex-full me-5">
						<img
							className="h-100"
							style={{ maxHeight: "400px" }}
							src={chooseSvg}
							alt="Two People Agreeing on Document Terms"></img>
					</div>
					<div className="d-inline-block nom">
						<Typography type="title">Best Use of a Bank Loan</Typography>
						<div className="d-flex flex-wrap mx-auto">
							<ul className="flex-full me-3">
								<li>Refinancing</li>
								<li>Purchasing large equipment</li>
								<li>Maintaining cash flow</li>
							</ul>
							<ul className="flex-full">
								<li>Debt consolidation</li>
								<li>Investment opportunities</li>
							</ul>
						</div>
					</div>
					<div className="d-inline-block nomm">
						<Typography className="text-center" type="title">Best Use of a Bank Loan</Typography>
						<div className="d-flex flex-wrap mx-auto">
							<ul className="flex-full me-3">
								<li>Refinancing</li>
								<li>Purchasing large equipment</li>
								<li>Maintaining cash flow</li>
								<li>Debt consolidation</li>
								<li>Investment opportunities</li>
							</ul>
						</div>
					</div>
				</Container>
			</Section>
		</Layout>
	);
};

export default LoanOfficers;
